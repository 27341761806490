/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable init-declarations */
import cn from 'classnames';
import React, {useEffect, useRef} from 'react';

import Image from '@/components/common/image/Image';
import {useMediaQueryMatch} from '@/hooks/use-media-query-match';

import styles from '../../Companies.module.css';
import {ICard} from '../../const';

interface ILogoCarouselProps {
	cards: ICard[];
	isLight: boolean;
}

export function LogoCarousel({cards, isLight}: ILogoCarouselProps) {
	const [isDesktop] = useMediaQueryMatch('(min-width: 1440px)');
	const carouselRef = useRef<HTMLDivElement>(null);
	const logos = cards.map(({imgLight, imgDark}) => (isLight ? imgLight : imgDark));
	const gap = isDesktop ? 11 : 8;

	useEffect(() => {
		let animationFrameId: number;
		const speed = 0.55;
		let lastTime = 0;
		const frameDuration = 1000 / 60;

		const calculatePosition = (currentPosition: number, firstItemWidth: number) => {
			const newPosition = currentPosition - speed;
			const needsReset = newPosition <= firstItemWidth * -2;
			return {newPosition, needsReset};
		};

		const updateDOM = (newPosition: number) => {
			if (carouselRef.current) {
				carouselRef.current.style.left = `${newPosition}px`;
			}
		};

		const animate = (time: number) => {
			if (!lastTime) {
				lastTime = time;
			}

			const timeDiff = time - lastTime;

			if (timeDiff >= frameDuration) {
				if (carouselRef.current) {
					const firstItem = carouselRef.current.firstElementChild as HTMLElement;
					const wFirstItem = firstItem.clientWidth;

					const {newPosition, needsReset} = calculatePosition(
						carouselRef.current.offsetLeft,
						wFirstItem,
					);

					if (needsReset) {
						carouselRef.current.appendChild(firstItem.cloneNode(true));
						carouselRef.current.removeChild(firstItem);
						updateDOM(newPosition + wFirstItem + gap);
					} else {
						updateDOM(newPosition);
					}
				}
				lastTime = time;
			}

			animationFrameId = requestAnimationFrame(animate);
		};

		animationFrameId = requestAnimationFrame(animate);

		return () => cancelAnimationFrame(animationFrameId);
	}, [gap]);

	return (
		<div className={styles.carousel}>
			<div className={styles.cards} ref={carouselRef}>
				{logos.map((logo, index) => (
					<div
						key={index}
						className={cn(styles.card, {
							[styles.cardDark]: !isLight,
							[styles.cardLight]: isLight,
						})}
					>
						<Image
							src={logo}
							alt="logo"
							layout="fill"
							objectFit={isDesktop ? 'none' : 'contain'}
							className={styles.logo}
						/>
					</div>
				))}
			</div>
		</div>
	);
}
