import cn from 'classnames';

import {Typography, VARIANTS} from '@/components/base/typography/Typography';
import {LogoCarousel} from '@/components/common/companies/components/logo-carousel/LogoCarousel';
import {Section, SECTION_STYLES} from '@/components/common/section/Section';

import styles from './Companies.module.css';
import {CARDS} from './const';
interface ICompaniesProps {
	isLight?: boolean;
	sectionId?: string;
	classNameSection?: string;
	classNameContainer?: string;
}
export function Companies({
	isLight = false,
	sectionId = null,
	classNameSection = '',
	classNameContainer = '',
}: ICompaniesProps) {
	return (
		<div className={cn(styles.root, classNameContainer, {[styles.rootLight]: isLight})}>
			<Section
				style={SECTION_STYLES.WITH_BREAKPOINTS}
				className={cn(styles.section, classNameSection)}
				direction="column"
				isFullWidth
				classNameFW={styles.rootFm}
				sectionId={sectionId}
			>
				<Typography
					variant={VARIANTS.h2}
					className={cn(styles.title, {[styles.titleDark]: !isLight, [styles.titleLight]: isLight})}
				>
					VK&nbsp;WorkSpace уже пользуются сотни российских компаний
				</Typography>
			</Section>
			<LogoCarousel cards={CARDS} isLight={isLight} />
		</div>
	);
}
