import {StaticImageData} from 'next/legacy/image';

import DarkIcon1 from './icons/dark/logo-1.webp';
import DarkIcon2 from './icons/dark/logo-2.webp';
import DarkIcon3 from './icons/dark/logo-3.webp';
import DarkIcon4 from './icons/dark/logo-4.webp';
import DarkIcon5 from './icons/dark/logo-5.webp';
import DarkIcon6 from './icons/dark/logo-6.webp';
import DarkIcon7 from './icons/dark/logo-7.webp';
import DarkIcon8 from './icons/dark/logo-8.webp';
import DarkIcon9 from './icons/dark/logo-9.webp';
import DarkIcon10 from './icons/dark/logo-10.webp';
import DarkIcon11 from './icons/dark/logo-11.webp';
import DarkIcon12 from './icons/dark/logo-12.webp';
import DarkIcon13 from './icons/dark/logo-13.webp';
import DarkIcon14 from './icons/dark/logo-14.webp';
import DarkIcon15 from './icons/dark/logo-15.webp';
import DarkIcon16 from './icons/dark/logo-16.webp';
import DarkIcon17 from './icons/dark/logo-17.webp';
import DarkIcon18 from './icons/dark/logo-18.webp';
import DarkIcon19 from './icons/dark/logo-19.webp';
import DarkIcon20 from './icons/dark/logo-20.webp';
import LightIcon1 from './icons/light/logo-1.webp';
import LightIcon2 from './icons/light/logo-2.webp';
import LightIcon3 from './icons/light/logo-3.webp';
import LightIcon4 from './icons/light/logo-4.webp';
import LightIcon5 from './icons/light/logo-5.webp';
import LightIcon6 from './icons/light/logo-6.webp';
import LightIcon7 from './icons/light/logo-7.webp';
import LightIcon8 from './icons/light/logo-8.webp';
import LightIcon9 from './icons/light/logo-9.webp';
import LightIcon10 from './icons/light/logo-10.webp';
import LightIcon11 from './icons/light/logo-11.webp';
import LightIcon12 from './icons/light/logo-12.webp';
import LightIcon13 from './icons/light/logo-13.webp';
import LightIcon14 from './icons/light/logo-14.webp';
import LightIcon15 from './icons/light/logo-15.webp';
import LightIcon16 from './icons/light/logo-16.webp';
import LightIcon17 from './icons/light/logo-17.webp';
import LightIcon18 from './icons/light/logo-18.webp';
import LightIcon19 from './icons/light/logo-19.webp';
import LightIcon20 from './icons/light/logo-20.webp';

export interface ICard {
	imgDark: StaticImageData;
	imgLight: StaticImageData;
}
export const CARDS: ICard[] = [
	{imgDark: DarkIcon1, imgLight: LightIcon1},
	{imgDark: DarkIcon2, imgLight: LightIcon2},
	{imgDark: DarkIcon3, imgLight: LightIcon3},
	{imgDark: DarkIcon4, imgLight: LightIcon4},
	{imgDark: DarkIcon5, imgLight: LightIcon5},
	{imgDark: DarkIcon6, imgLight: LightIcon6},
	{imgDark: DarkIcon7, imgLight: LightIcon7},
	{imgDark: DarkIcon8, imgLight: LightIcon8},
	{imgDark: DarkIcon9, imgLight: LightIcon9},
	{imgDark: DarkIcon10, imgLight: LightIcon10},
	{imgDark: DarkIcon11, imgLight: LightIcon11},
	{imgDark: DarkIcon12, imgLight: LightIcon12},
	{imgDark: DarkIcon13, imgLight: LightIcon13},
	{imgDark: DarkIcon14, imgLight: LightIcon14},
	{imgDark: DarkIcon15, imgLight: LightIcon15},
	{imgDark: DarkIcon16, imgLight: LightIcon16},
	{imgDark: DarkIcon17, imgLight: LightIcon17},
	{imgDark: DarkIcon18, imgLight: LightIcon18},
	{imgDark: DarkIcon19, imgLight: LightIcon19},
	{imgDark: DarkIcon20, imgLight: LightIcon20},
];
